import React from "react";
import styled from "styled-components";
// Assets
import QuoteIcon from "../../assets/svg/Quotes";

export default function TestimonialBox({text, author}) {
    return (
        <Wrapper className="darkBg radius8 flexNullCenter flexColumn">
            <QuoteWrapper>
                <QuoteIcon/>
            </QuoteWrapper>
            <InnerWrapper>
                <p className="whiteColor font16" style={{paddingBottom: "30px"}}>
                    {text}
                </p>
                <p className="greenColor font16" style={{alignSelf: 'flex-end'}}>
                    <em>{author}</em>
                </p>
            </InnerWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  margin-top: 30px;
  height: 230px;
`;
const QuoteWrapper = styled.div`
  position: relative;
  top: -40px;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;
