import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";

export default function Services() {
    return (
        <Wrapper id="services">
            <div className="lightBg" style={{padding: "50px 0"}}>
                <div className="container">
                    <ClientSlider/>
                </div>
            </div>
            <div className="whiteBg" style={{paddingTop: "60px"}}>
                <div className="container">
                    <HeaderInfo>
                        <h1 className="font40 extraBold">Unsere Dienstleistungen</h1>
                        <p className="font18 m-top">
                            Die Instandhaltung nach DIN31051 ist eine wichtige Maßnahme zur Bewahrung und
                            Wiederherstellung
                            <br/>des Soll-Zustands, sowie zur Feststellung und Beurteilung des Ist-Zustands
                            ihrer Anlage oder ihres Gebäudes während ihrer Lebenszyklen.
                        </p>
                    </HeaderInfo>
                    <ServiceBoxRow className="flex">
                        <ServiceBoxWrapper>
                            <ServiceBox
                                icon="technisch"
                                title="Technischer Gebäudeservice"
                                subtitle="Der technische Gebäudeservice ist ein unverzichtbarer Bestandteil für eine effiziente und reibungslose Gebäudeverwaltung."
                            />
                        </ServiceBoxWrapper>
                        <ServiceBoxWrapper>
                            <ServiceBox
                                icon="objekt"
                                title="Objektbetreuung"
                                subtitle="Wir betreuen ihre Immobilie zuverlässig und stellen sicher, dass Ihre Anlagen immer auf dem neuesten Stand sind."
                            />
                        </ServiceBoxWrapper>
                        <ServiceBoxWrapper>
                            <ServiceBox
                                icon="energy"
                                title="Energieberatung"
                                subtitle="Wir bieten eine maßgeschneiderte Beratung, um Ihre Energiekosten zu senken und Ihre Energieeffizienz zu maximieren."
                            />
                        </ServiceBoxWrapper>
                        <ServiceBoxWrapper>
                            <ServiceBox icon="kaufm" title="Kaufmännischer Gebäudeservice"
                                        subtitle="Mit unserem kaufmännischen Gebäudeservice können Sie sich auf eine zuverlässige Verwaltung Ihrer Gebäudeinfrastruktur verlassen."/>
                        </ServiceBoxWrapper>
                    </ServiceBoxRow>
                </div>
                <div className="lightBg">
                    <div className="container">
                        <Advertising className="flexSpaceCenter">
                            <AddLeft>
                                <h4 className="font15 semiBold">Ein paar Wörter über uns</h4>
                                <h2 className="font50 extraBold">Instandhaltung mit Weitblick</h2>
                                <p className="font18">
                                    Unser Motto "Instandhaltung mit Weitblick" ist der Leitfaden für alles, was wir tun. Unser Ziel ist es, nicht nur aktuelle Probleme zu beheben, sondern auch langfristige Lösungen zu schaffen, die den Wert Ihrer Immobilien steigern und Sie vor zukünftigen Herausforderungen schützen.
                                </p>
                                <ButtonsRow className="flexNullCenter" style={{margin: "30px 0"}}>
                                    <div style={{width: "190px"}}>
                                        <FullButton title="Kontaktieren" action={() => {
                                            const contact = document.getElementById('footer');
                                            contact.scrollIntoView({ behavior: 'smooth', block: 'end'})}}/>
                                    </div>
                                </ButtonsRow>
                            </AddLeft>
                            <AddRight>
                                <AddRightInner>
                                    <div className="flexNullCenter">
                                        <AddImgWrapp1 className="flexCenter">
                                            <img src={AddImage1} alt="office"/>
                                        </AddImgWrapp1>
                                        <AddImgWrapp2>
                                            <img src={AddImage2} alt="office"/>
                                        </AddImgWrapp2>
                                    </div>
                                    <div className="flexNullCenter">
                                        <AddImgWrapp3>
                                            <img src={AddImage3} alt="office"/>
                                        </AddImgWrapp3>
                                        <AddImgWrapp4>
                                            <img src={AddImage4} alt="office"/>
                                        </AddImgWrapp4>
                                    </div>
                                </AddRightInner>
                            </AddRight>
                        </Advertising>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
