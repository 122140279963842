import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import ImgKaufm from "../../assets/img/kaufm.png";
import ImgTechnisch from "../../assets/img/technisch.png";
import ImgEnergy from "../../assets/img/energy.png";
import ImgObjekt from "../../assets/img/objekt.png";

export default function ServiceBox({icon, title, subtitle}) {
  let image;

  switch (icon) {
    case "kaufm":
      image = ImgKaufm;
      break;
    case "technisch":
      image = ImgTechnisch;
      break;
    case "energy":
      image = ImgEnergy;
      break;
    case "objekt":
      image = ImgObjekt;
      break;
    default:
      image = ImgKaufm;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <Img src={image} alt="Bild kaufmännisch"/>
      <TitleStyle className="font25 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font16">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Img = styled.img`
    width: 70px;
    height: auto;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  min-height: 5rem;
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  justfiy-content: center;
  align-items: center;
  margin: 1rem 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;
