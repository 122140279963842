import * as React from "react";

import logo from "../../assets/img/logo.png"
import styled from "styled-components";

function SvgComponent(props) {
  return (
      <ImgWrapper src={logo} alt="tgh-logo"/>
  );
}

const ImgWrapper = styled.img`
  width: 120px;
  margin: 1rem;
  @media (max-width: 760px) {
    width: 70px;
  }
`;

export default SvgComponent;
